
.v1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.display {


    pointer-events: none;
    z-index: 2;
    color: white;
}

.white{
}

.blinkRed{
    animation: blinker .5s linear infinite;
}

@keyframes blinker {
    50% {
        color: red;
    }
}

.mainClickSurfaceContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.addLife,
.subtractLife {
    flex: 1;
    /*opacity: .1;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.addLife {
    background-color: rgba(0, 200, 0, 0.05);
    /*background-color: green;*/
}

.subtractLife {
    background-color: rgba(200, 0, 0, 0.05);
}

.reset {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #333333;
}

.plus,
.minus {
    background-color: #444;
}

.plus {
    clip-path: polygon(35% 35%, 35% 0, 65% 0, 65% 35%, 100% 35% ,100% 65%, 65% 65%, 65% 100%, 35% 100%, 35% 65%, 0 65%, 0 35%);
}

.minus {
    clip-path: polygon(0 35%, 100% 35%, 100% 65%, 0 65%);
}