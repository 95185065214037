.App {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: auto;

    background-color: #333333;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
