
.v2_modal{
    background-color: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
}

.v2_modalButtonWrapper,
.v2_modalContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v2_modalButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

}

.v2_modalButton {
    /*flex: 1;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.v2_yasButton {
    background-color: lightgreen;
}

.v2_nahButton {
    background-color: lightcoral;
}