.collapsibleElement {
    margin-top: 10px;
}

.collapsibleElement__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: lightgray;
    height: 30px;
    padding-left: 10px;
    border-radius: 5px;
}

.collapsibleElement__headerContent h4 {
    margin: 0;
}

.collapsibleElement__collapseButton,
.collapsibleElement__collapseButton:hover,
.collapsibleElement__collapseButton:active {
    background-color: rgba(0, 0, 0, 0);
}

.collapsibleElement__collapseButton {
    height: 28px;
    width: 28px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapsibleElement__collapseButton svg {
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s; /* Safari */

    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.collapsibleElement__collapseButton--collapsed svg {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.collapsibleElement__content {
    padding: 5px 10px 0 10px;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    transition: height 0.5s;
    -webkit-transition: height 0.5s; /* Safari */
}