.v2_main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.v2_menuButton{
    background-color: transparent;
    border: none;
    position: fixed;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.v2_menu {
    z-index: 4;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/

    padding-top: 10%;
}

.v2_menu .resetButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.v2_menu_historyOptions {
    display: flex;
    flex-direction: column;
}

.v2_menu_historyOptionGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.v2_menu_showHistoryForm {
    display: flex;
    flex-direction: column;
}

.v2_menu_showHistoryOption {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.v2_globeContainer {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v2_lifeGlobe {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v2_lifeGlobeRed {
    background-color: #864343;
}

.v2_lifeGlobeGreen {
    background-color: #438543;
}

.v2_lifeGlobeGray {
    background-color: #6a5393;
    /*background-color: #747474;*/
}

.v2_lifeGlobeRedLightish,
.v2_lifeGlobeGreenLightish,
.v2_lifeGlobeGrayLightish {
    position: absolute;
}

.v2_lifeGlobeRedLightish {
    background-color: indianred;
}


.v2_lifeGlobeGreenLightish {
    background-color: #6ad36b;
}


.v2_lifeGlobeGrayLightish {
    background-color: #a48cdb;
    /*background-color: #b2b2b2;*/
}

.v2_lifeGlobeSheen {
    background-color: white;
    /*opacity: .7;*/
    opacity: .8;
    position: absolute;
}

.v2_lifeGlobeLight {
    background-color: white;
    /*opacity: .4;*/
    opacity: .5;
    position: absolute;
}

.v2_lifeGlobeText {
    color: black;
    opacity: .7;
    z-index: 3;
}

.v2_historyElement{
    margin: .1rem 0 .1rem .2rem;
}

.v2_historyElementText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.v2_historyPrimary {
    margin-bottom: .3rem;
}

.v2_changeButton {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v2_lifeGlobeRed.v2_fresh {
    animation: growFlashRed 1s;
}

.v2_lifeGlobeGreen.v2_fresh {
    animation: growFlashGreen 1s;
}

.v2_lifeGlobeRed.v2_highLight {
    animation: pulseRed 1s infinite;
}

.v2_lifeGlobeGreen.v2_highLight {
    animation: pulseGreen 1s infinite;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes pulseGreen {
    0%   {background-color: #438543;}
    50%  {background-color: #e9ffe3;}
    100% {background-color: #438543;}
}

/* Standard syntax */
@keyframes pulseGreen {
    0%   {background-color: #438543;}
    50%  {background-color: #e9ffe3;}
    100% {background-color: #438543;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes pulseRed {
    0%   {background-color: #864343;}
    50%  {background-color: #ffdbd8;}
    100% {background-color: #864343;}
}

/* Standard syntax */
@keyframes pulseRed {
    0%   {background-color: #864343;}
    50%  {background-color: #ffdbd8;}
    100% {background-color: #864343;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes grow {
    0%   {background-color: #864343;}
    50%  {background-color: #ffdbd8;}
    100% {background-color: #864343;}
}

/* Standard syntax */
@keyframes growFlashGreen {
    0%   {
        background-color: #438543;
        transform: scale(0.1);
    }
    20%  {
        transform: scale(1);
    }
    50%  {
        background-color: #e9ffe3;
    }
    100% {
        background-color: #438543;
    }
}

/* Standard syntax */
@keyframes growFlashRed {
    0%   {
        background-color: #864343;
        transform: scale(0.1);
    }
    20%  {
        transform: scale(1);
    }
    50%  {
        background-color: #ffdbd8;
    }
    100% {
        background-color: #864343;
    }
}

.v2_changeButtonIcon {
    background-color: #dddddd;
}

.v2_addLifeButtonIcon {
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
}

.v2_subtractLifeButtonIcon {
    clip-path: polygon(100% 0, 50% 100%, 0 0);
}
